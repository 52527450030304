// animations du menu burger sur mobile + accessibilité du sous menu sur desktop

jQuery(document).ready(function ($) {

	// menu burger déroulant
	$('#burger').click(function () {
		$(this).toggleClass('is-active');
		$('.burger-box').toggleClass("clickMenuEight");
		if ($(this).hasClass('is-active')) {
			$('#inner-header nav').slideToggle();
			$('#inner-header nav').css('display', 'inherit');
			
		} else {
			$('#inner-header nav').slideToggle();
		}
	});

	$('#inner-header nav a').click(function () {
		$('#burger').toggleClass('is-active');
		$('.burger-box').toggleClass("clickMenuEight");
		$('#inner-header nav').slideToggle();
	});

	// menu burger : sous-menu déroulant
	$('.menu-item-has-children').click(function () {
		if ($(window).width() < 1030) {
			$(this).children('.sub-menu').slideToggle();
			$(this).toggleClass('dropped-down');
		}
	});

	// menu burger : sous-menu déroulant
	$('#cp-sumenu-title').click(function () {
		if ($(window).width() < 1030) {
			$('#menu-cp-submenu-wrap').slideToggle();
			$(this).toggleClass('dropped-down');
		}
	});

	// accessibilité sous-menu (focus + navigation clavier)
	$('.nav >ul >li').each(function () {
		$(this).children().focus(function () {
			if ($(this).parent().hasClass('menu-item-has-children')) {
				$(this).parent().addClass('open');
				$(this).attr('aria-expanded', "true");
			} else {
				$(this).parent().removeClass('open');
				$(this).attr('aria-expanded', "false");
			}

			event.preventDefault();
			return false;
		});

		$(this).children().children('li:last-child').focusout(function () {
			$(this).parents('.open').removeClass('open');
		});
	});

});